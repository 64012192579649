/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.5.3/dist/css/bootstrap.min.css
 * - /npm/cropperjs@1.5.11/dist/cropper.min.css
 * - /npm/jquery-datetimepicker@2.5.21/build/jquery.datetimepicker.min.css
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.carousel.min.css
 * - /npm/quill@1.3.7/dist/quill.core.min.css
 * - /npm/select2@4.1.0-rc.0/dist/css/select2.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
